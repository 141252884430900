@import "../../variables.scss";


[slot="title"] {
  font-size: 1.2rem;
  color: $orca-light-30;
  display: flex;
  flex-direction: row;

  .title-span {
    margin: 0 1.5rem;
  }
}
  
[slot="body"] {
  div.loading-animation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .manage-categories-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $orca-primary;
    margin-bottom: 1.125rem;

    .header-left {
      display: flex;
      flex-direction: column;
    
      .description-title {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
    
      .description-subtitle {
        font-size: 0.8rem;
        color: $orca-light-50;
        margin-bottom: 0.2rem;
      }
    }

    .header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    
      .header-right-span {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 0.4rem 0.6rem;

        .category-search {
          width: 18.75rem;
          margin-left: 0.6rem;
        }
      }
    }
  } 
  
  div.categories-list {
    flex: 1;
    margin-top: 2.625rem;
    overflow: hidden auto;
    padding: 0 3.75rem 0 3.125rem;

    .categories-list-item {
        margin-bottom: 1rem;
    }
  }

  div.categories-drag-item .categories-list-item:hover {
    background-color: $silver;
    cursor: grab;
  }
}

[slot="footer"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .default-button-save {
    margin-left: .375rem;
  }
}