@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./variables.scss";

html,
body,
div.viewport {
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100vw;
  visibility: visible;
}

body {
  font-family: $font-ibm-plex-sans;

  &.loading {
    inmoment-element-app-top-bar,
    div.cdk-overlay-container {
      visibility: hidden;
    }
  }
}

.label {
  text-transform: none;
}

div {
  &.menu {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.15);
  }

  &.toolbar {
    min-height: 60px;
    padding: 0 7px 0 12px;
  }

  &.viewport-rest {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &.loading > div {
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
}
