@import "../../variables.scss";

.logout {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logout h1 {
  font-size: xx-large;
  font-weight: 400;
}

.logout>div {
  position: relative;
  top: -150px;
}

.logout iframe {
  visibility: hidden;
  width: 0;
  height: 0;
}
