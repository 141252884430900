[slot="title"] {
  font-size: 1.2rem;
  color: #66737e;
  display: flex;
  flex-direction: row; }
  [slot="title"] .title-span {
    margin: 0 1.5rem; }

[slot="body"] .manage-reports-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #253746;
  margin-bottom: 1.125rem; }
  [slot="body"] .manage-reports-header .header-left {
    display: flex;
    flex-direction: column; }
    [slot="body"] .manage-reports-header .header-left .description-title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem; }
    [slot="body"] .manage-reports-header .header-left .description-subtitle {
      font-size: 0.8rem;
      color: #929ba3;
      margin-bottom: 0.2rem; }
  [slot="body"] .manage-reports-header .header-right {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
    [slot="body"] .manage-reports-header .header-right .header-right-span {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0.4rem 0.6rem; }
      [slot="body"] .manage-reports-header .header-right .header-right-span .report-search {
        width: 18.75rem;
        margin-left: 0.6rem; }

[slot="footer"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; }
  [slot="footer"] .default-button-save {
    margin-left: .375rem; }

div.reports-list {
  flex: 1;
  margin-top: 2.625rem;
  overflow: hidden auto;
  padding: 0 3.75rem 0 3.125rem; }
  div.reports-list .reports-list-item {
    margin-bottom: 1rem; }

div.reports-drag-item .reports-list-item:hover {
  background-color: #f9fafb;
  cursor: grab; }
