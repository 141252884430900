[slot="title"] {
  font-size: 1.2rem;
  color: #66737e;
  display: flex;
  flex-direction: row; }
  [slot="title"] .title-span {
    margin: 0 1.5rem; }

[slot="body"] {
  direction: flex;
  flex-direction: column; }

[slot="footer"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; }
  [slot="footer"] .default-button-save {
    margin-left: .375rem; }

div.create-report-dialog-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

div.create-report-top {
  border-bottom: 1px solid #929ba3;
  padding-bottom: 0.5rem;
  margin-bottom: 3.125rem; }
  div.create-report-top .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #253746;
    display: flex;
    flex-direction: row;
    align-items: center; }
  div.create-report-top .subtitle {
    font-size: 0.8rem;
    color: #929ba3;
    margin-bottom: 0.2rem; }

div.create-report-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 7rem; }
  div.create-report-name .name-input {
    min-width: 18rem; }

div.link-report {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }
  div.link-report .report-id {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 21.25rem; }
    div.link-report .report-id .report-id-title {
      font-size: 0.85rem;
      color: #465562;
      min-width: 12.5rem; }
    div.link-report .report-id .report-id-input-field {
      max-width: 7.5rem; }

div.create-report-name .description, .link-report .description {
  min-width: 30rem; }

div.create-report-name .title, .link-report .title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  color: #253746; }

div.create-report-name .subtitle, .link-report .subtitle {
  font-size: 0.8rem;
  color: #929ba3;
  margin-bottom: 1rem; }
