@import "../../variables.scss";

div.report-detail-wrapper {
    background-color: $white-primary;
    border-radius: 0.375rem;
    border: 1px solid $grey-light-80;
    border-left: 0.56rem solid $orca-primary;
    padding: 1rem 0.5rem 1rem 3.125rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    div.report-detail-title {
        flex: 33;
    }

    div.report-detail-main {
        align-items: inherit;
        display: flex;
        flex: 67;
        flex-direction: row;

        div.report-detail-data {
            border-left: 1px solid $grey-light-80;
            flex: 25;
            padding: 0 0.5rem;
            display: flex;
            flex-direction: column;

            & > * {
                overflow-wrap: anywhere;
            }
        }
    }
}

div.rename-report-modal {
    width: 37.5rem;
    height: 18rem;
    background: white;
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    div.rename-report {
        margin: 3rem 10rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: $orca-primary;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .rename-report-input {
          margin-top: 2rem;
          width: 16rem;
        }
      
        div.rename-report-footer {
          margin-top: 2rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        
          .default-button-clone {
            margin-left: .375rem;
          }
        }
      }
}
