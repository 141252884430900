@import "../../variables.scss";

.empty-page-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.create-entity-button {
  margin-top: 36px;
  padding: 9px 17px;
}
