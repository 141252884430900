// Fonts
$font-ibm-plex-sans: "IBM Plex Sans", sans-serif;
$font-montserrat: "Montserrat", sans-serif;

/// Tint: Slightly tint a color by adding a percentage of white to it.
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}

// Blue (CX)
$blue-primary: rgb(0,88,255);
$blue-light-60: rgb(153,188,255);
// Green
$green-light-60: rgb(165,218,201);
// Grey
$grey-light-15: rgb(96,96,96);
$grey-light-40: rgb(143,143,143);
$grey-light-80: rgb(218,218,218);
$grey-light-90: rgb(236,236,236);
// Orca
$orca-primary: rgb(37,55,70);
$orca-light-99:  tint($orca-primary, 99%);
$orca-light-95:  tint($orca-primary, 95%);
$orca-light-90:  tint($orca-primary, 90%);
$orca-light-80:  tint($orca-primary, 80%);
$orca-light-70:  tint($orca-primary, 70%);
$orca-light-60:  tint($orca-primary, 60%);
$orca-light-50:  tint($orca-primary, 50%);
$orca-light-40:  tint($orca-primary, 40%);
$orca-light-30:  tint($orca-primary, 30%);
$orca-light-15:  tint($orca-primary, 15%);
// Purple (EX)
$purple-light-60: rgb(209,168,255);
// Red
$red-primary: rgb(249,66,58);
// Silver
$silver: rgb(249,250,251);
// Teal (MX)
$teal-light-60: rgb(167, 239, 229);
// Yellow
$yellow-primary: rgb(240,179,35);
// White
$white-primary: rgb(255,255,255);
$white-light-70: rgba(255, 255, 255, 0.3);
