@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
html,
body,
div.viewport {
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100vw;
  visibility: visible; }

body {
  font-family: "IBM Plex Sans", sans-serif; }
  body.loading inmoment-element-app-top-bar,
  body.loading div.cdk-overlay-container {
    visibility: hidden; }

.label {
  text-transform: none; }

div.menu {
  border-radius: 0 0 4px 4px;
  box-shadow: 0 11px 16px 0 rgba(0, 0, 0, 0.15); }

div.toolbar {
  min-height: 60px;
  padding: 0 7px 0 12px; }

div.viewport-rest {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }

div.loading > div {
  height: 100%;
  margin: 0 auto;
  position: relative; }

.logout {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.logout h1 {
  font-size: xx-large;
  font-weight: 400; }

.logout > div {
  position: relative;
  top: -150px; }

.logout iframe {
  visibility: hidden;
  width: 0;
  height: 0; }

div.snackbar-container {
  text-align: left; }

div.custom-report-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 3.75rem; }
  div.custom-report-wrapper div.custom-report-name-field {
    flex: 56 1;
    margin-right: 0.75rem; }
  div.custom-report-wrapper div.custom-report-id-field {
    flex: 44 1;
    margin-right: 0.5rem; }
  div.custom-report-wrapper div.custom-report-action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around; }

[slot="title"] {
  font-size: 1.2rem;
  color: #66737e;
  display: flex;
  flex-direction: row; }
  [slot="title"] .title-span {
    margin: 0 1.5rem; }

[slot="body"] {
  direction: flex;
  flex-direction: column; }

[slot="footer"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; }
  [slot="footer"] .default-button-save {
    margin-left: .375rem; }

div.add-or-edit-dialog-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

div.add-or-edit-top {
  border-bottom: 1px solid #929ba3;
  padding-bottom: 0.5rem;
  margin-bottom: 3.125rem; }
  div.add-or-edit-top .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #253746;
    display: flex;
    flex-direction: row;
    align-items: center; }
  div.add-or-edit-top .subtitle {
    font-size: 0.8rem;
    color: #929ba3;
    margin-bottom: 0.2rem; }

div.add-or-edit-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 3.8rem; }
  div.add-or-edit-row .name-input {
    width: 18rem; }
  div.add-or-edit-row .xi-dropdown {
    width: 18rem; }

div.add-or-edit-row .description {
  min-width: 30rem; }

div.add-or-edit-row .title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: #253746; }

div.add-or-edit-row .subtitle {
  font-size: 0.8rem;
  color: #929ba3;
  margin-bottom: 1rem; }

div.manage-reports-section div.custom-report-title {
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  color: #66737e;
  margin: 0.5rem 0 0 0.6rem; }
  div.manage-reports-section div.custom-report-title div.custom-report-name {
    flex: 48 1; }
  div.manage-reports-section div.custom-report-title div.custom-report-id {
    flex: 53 1; }

div.manage-reports-section div.add-custom-report-button {
  margin-top: 0.5rem; }

div.clone-report-modal {
  width: 37.5rem;
  height: 18rem;
  background: white;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  div.clone-report-modal div.create-report-dialog-loading {
    width: 18rem;
    height: 18rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  div.clone-report-modal div.clone-report {
    margin: 3rem 10rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #253746;
    display: flex;
    flex-direction: column;
    align-items: center; }
    div.clone-report-modal div.clone-report .clone-report-input {
      margin-top: 2rem;
      width: 16rem; }
    div.clone-report-modal div.clone-report div.clone-report-footer {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      div.clone-report-modal div.clone-report div.clone-report-footer .default-button-clone {
        margin-left: .375rem; }

[slot="title"] {
  font-size: 1.2rem;
  color: #66737e;
  display: flex;
  flex-direction: row; }
  [slot="title"] .title-span {
    margin: 0 1.5rem; }

[slot="body"] {
  direction: flex;
  flex-direction: column; }

[slot="footer"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; }
  [slot="footer"] .default-button-save {
    margin-left: .375rem; }

div.create-report-dialog-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

div.create-report-top {
  border-bottom: 1px solid #929ba3;
  padding-bottom: 0.5rem;
  margin-bottom: 3.125rem; }
  div.create-report-top .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #253746;
    display: flex;
    flex-direction: row;
    align-items: center; }
  div.create-report-top .subtitle {
    font-size: 0.8rem;
    color: #929ba3;
    margin-bottom: 0.2rem; }

div.create-report-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 7rem; }
  div.create-report-name .name-input {
    min-width: 18rem; }

div.link-report {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }
  div.link-report .report-id {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 21.25rem; }
    div.link-report .report-id .report-id-title {
      font-size: 0.85rem;
      color: #465562;
      min-width: 12.5rem; }
    div.link-report .report-id .report-id-input-field {
      max-width: 7.5rem; }

div.create-report-name .description, .link-report .description {
  min-width: 30rem; }

div.create-report-name .title, .link-report .title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  color: #253746; }

div.create-report-name .subtitle, .link-report .subtitle {
  font-size: 0.8rem;
  color: #929ba3;
  margin-bottom: 1rem; }

div.dashboard-holder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden; }
  div.dashboard-holder div.dashboard-loader {
    align-items: center;
    display: flex;
    flex-grow: 3;
    justify-content: center; }
  div.dashboard-holder iframe {
    border: none;
    display: block;
    flex-grow: 1;
    width: 100%; }
  div.dashboard-holder div.dashboard-error {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center; }
    div.dashboard-holder div.dashboard-error > div {
      max-width: 16rem;
      text-align: center; }
    div.dashboard-holder div.dashboard-error div.error-image {
      height: 96px; }
    div.dashboard-holder div.dashboard-error div.primary-text {
      margin-top: 0.2rem; }
    div.dashboard-holder div.dashboard-error div.secondary-text {
      color: #66737e;
      margin-top: 1rem; }

.empty-page-body {
  display: flex;
  flex-direction: column;
  align-items: center; }

div.create-entity-button {
  margin-top: 36px;
  padding: 9px 17px; }

div.category-detail-wrapper {
  background-color: white;
  border-radius: 0.375rem;
  border: 1px solid #dadada;
  border-left: 0.56rem solid #253746;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem; }
  div.category-detail-wrapper.category-status-disabled, div.category-detail-wrapper.category-status-pending {
    padding-right: 2.75rem; }
  div.category-detail-wrapper.category-status-disabled {
    opacity: 0.4; }

div.category-detail-grabber {
  width: 1.2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

div.category-detail-summary {
  display: flex;
  flex: 25 1;
  flex-direction: column; }

div.category-detail-section {
  display: flex;
  flex: 75 1;
  flex-direction: row; }

div.category-detail-data {
  border-right: 1px solid #dadada;
  display: flex;
  flex: 25 1;
  flex-direction: column;
  padding: 0 0.5rem; }
  div.category-detail-data:last-child {
    border: none; }
  div.category-detail-data > * {
    overflow-wrap: anywhere; }

div.category-detail-actions {
  align-self: center; }

[slot="title"] {
  font-size: 1.2rem;
  color: #66737e;
  display: flex;
  flex-direction: row; }
  [slot="title"] .title-span {
    margin: 0 1.5rem; }

[slot="body"] div.loading-animation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

[slot="body"] .manage-categories-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #253746;
  margin-bottom: 1.125rem; }
  [slot="body"] .manage-categories-header .header-left {
    display: flex;
    flex-direction: column; }
    [slot="body"] .manage-categories-header .header-left .description-title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem; }
    [slot="body"] .manage-categories-header .header-left .description-subtitle {
      font-size: 0.8rem;
      color: #929ba3;
      margin-bottom: 0.2rem; }
  [slot="body"] .manage-categories-header .header-right {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
    [slot="body"] .manage-categories-header .header-right .header-right-span {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0.4rem 0.6rem; }
      [slot="body"] .manage-categories-header .header-right .header-right-span .category-search {
        width: 18.75rem;
        margin-left: 0.6rem; }

[slot="body"] div.categories-list {
  flex: 1 1;
  margin-top: 2.625rem;
  overflow: hidden auto;
  padding: 0 3.75rem 0 3.125rem; }
  [slot="body"] div.categories-list .categories-list-item {
    margin-bottom: 1rem; }

[slot="body"] div.categories-drag-item .categories-list-item:hover {
  background-color: #f9fafb;
  cursor: -webkit-grab;
  cursor: grab; }

[slot="footer"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; }
  [slot="footer"] .default-button-save {
    margin-left: .375rem; }

div.report-tabs-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: inherit;
  width: 100%; }
  div.report-tabs-wrapper > div {
    width: inherit; }

div.report-detail-wrapper {
  background-color: white;
  border-radius: 0.375rem;
  border: 1px solid #dadada;
  border-left: 0.56rem solid #253746;
  padding: 1rem 0.5rem 1rem 3.125rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }
  div.report-detail-wrapper div.report-detail-title {
    flex: 33 1; }
  div.report-detail-wrapper div.report-detail-main {
    align-items: inherit;
    display: flex;
    flex: 67 1;
    flex-direction: row; }
    div.report-detail-wrapper div.report-detail-main div.report-detail-data {
      border-left: 1px solid #dadada;
      flex: 25 1;
      padding: 0 0.5rem;
      display: flex;
      flex-direction: column; }
      div.report-detail-wrapper div.report-detail-main div.report-detail-data > * {
        overflow-wrap: anywhere; }

div.rename-report-modal {
  width: 37.5rem;
  height: 18rem;
  background: white;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  div.rename-report-modal div.rename-report {
    margin: 3rem 10rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #253746;
    display: flex;
    flex-direction: column;
    align-items: center; }
    div.rename-report-modal div.rename-report .rename-report-input {
      margin-top: 2rem;
      width: 16rem; }
    div.rename-report-modal div.rename-report div.rename-report-footer {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      div.rename-report-modal div.rename-report div.rename-report-footer .default-button-clone {
        margin-left: .375rem; }

[slot="title"] {
  font-size: 1.2rem;
  color: #66737e;
  display: flex;
  flex-direction: row; }
  [slot="title"] .title-span {
    margin: 0 1.5rem; }

[slot="body"] .manage-reports-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #253746;
  margin-bottom: 1.125rem; }
  [slot="body"] .manage-reports-header .header-left {
    display: flex;
    flex-direction: column; }
    [slot="body"] .manage-reports-header .header-left .description-title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem; }
    [slot="body"] .manage-reports-header .header-left .description-subtitle {
      font-size: 0.8rem;
      color: #929ba3;
      margin-bottom: 0.2rem; }
  [slot="body"] .manage-reports-header .header-right {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
    [slot="body"] .manage-reports-header .header-right .header-right-span {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0.4rem 0.6rem; }
      [slot="body"] .manage-reports-header .header-right .header-right-span .report-search {
        width: 18.75rem;
        margin-left: 0.6rem; }

[slot="footer"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; }
  [slot="footer"] .default-button-save {
    margin-left: .375rem; }

div.reports-list {
  flex: 1 1;
  margin-top: 2.625rem;
  overflow: hidden auto;
  padding: 0 3.75rem 0 3.125rem; }
  div.reports-list .reports-list-item {
    margin-bottom: 1rem; }

div.reports-drag-item .reports-list-item:hover {
  background-color: #f9fafb;
  cursor: -webkit-grab;
  cursor: grab; }

.app-title-bar {
  z-index: 2; }

.categories-dropdown {
  min-width: 9rem; }

div.viewport-rest.computer .heading-button-wrapper {
  min-width: 11.7rem; }

div.viewport-rest.computer .app-title-bar-icons {
  padding-left: 1.125rem;
  border-left: 1px solid rgba(255, 255, 255, 0.3); }

div.viewport-rest.computer .categories-dropdown {
  max-width: 12.5rem; }

div.viewport-rest.computer .categories-dropdown-container {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding-right: 0.75rem; }

div.viewport-rest.tablet .categories-dropdown {
  width: 12rem; }

div.viewport-rest.tablet div.app-title-bar-icons {
  padding-left: 1rem; }

div.viewport-rest.mobile div.app-title-bar-icons {
  padding-left: 1rem; }

div.app-title-bar-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  min-height: 2.875rem; }

.entity-loading-mask {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex: 1 1; }
  .entity-loading-mask h1 {
    color: #253746;
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.2px; }

div.report-app-container {
  display: flex;
  flex-direction: column; }

