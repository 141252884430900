@import "../../variables.scss";

div.custom-report-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 3.75rem;

    div.custom-report-name-field {
        flex: 56;
        margin-right: 0.75rem;
    }
    div.custom-report-id-field {
        flex: 44;
        margin-right: 0.5rem;
    }

    div.custom-report-action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}
