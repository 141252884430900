div.report-tabs-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: inherit;
    width: 100%;

    & > div {
        width: inherit;
    }
}