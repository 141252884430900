@import "../../variables.scss";

div.category-detail-wrapper {
    background-color: $white-primary;
    border-radius: 0.375rem;
    border: 1px solid $grey-light-80;
    border-left: 0.56rem solid $orca-primary;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    &.category-status-disabled,
    &.category-status-pending {
        padding-right: 2.75rem;
    }

    &.category-status-disabled {
        opacity: 0.4;
    }
}

div.category-detail-grabber {
    width: 1.2rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

div.category-detail-summary {
    display: flex;
    flex: 25;
    flex-direction: column;
}

div.category-detail-section {
    display: flex;
    flex: 75;
    flex-direction: row;
}

div.category-detail-data {
    border-right: 1px solid $grey-light-80;
    display: flex;
    flex: 25;
    flex-direction: column;
    padding: 0 0.5rem;

    &:last-child {
        border: none;
    }

    & > * {
        overflow-wrap: anywhere;
    }
}

div.category-detail-actions {
    align-self: center;
}
