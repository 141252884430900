@import "../../variables.scss";


[slot="title"] {
    font-size: 1.2rem;
    color: $orca-light-30;
    display: flex;
    flex-direction: row;
  
    .title-span {
    margin: 0 1.5rem;
    }
}
  
[slot="body"] {
    direction: flex;
    flex-direction: column;
}
  
[slot="footer"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  
    .default-button-save {
      margin-left: .375rem;
    }
}

div.add-or-edit-dialog-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

div.add-or-edit-top {
  border-bottom: 1px solid $orca-light-50;
  padding-bottom: 0.5rem;
  margin-bottom: 3.125rem;
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: $orca-primary;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .subtitle {
    font-size: 0.8rem;
    color: $orca-light-50;
    margin-bottom: 0.2rem;
  }
}
  
div.add-or-edit-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 3.8rem;

  .name-input {
    width: 18rem;
  }

  .xi-dropdown {
    width: 18rem;
  }
}

div.add-or-edit-row .description {
  min-width: 30rem;
}

div.add-or-edit-row .title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: $orca-primary;
}

div.add-or-edit-row .subtitle {
  font-size: 0.8rem;
  color: $orca-light-50;
  margin-bottom: 1rem;
}

div.manage-reports-section {
  div.custom-report-title {
      font-size: 0.8rem;
      display: flex;
      flex-direction: row;
      color: $orca-light-30;
      margin: 0.5rem 0 0 0.6rem;

      div.custom-report-name {
          flex: 48;
      }
      div.custom-report-id {
          flex: 53;
      }
  }

  div.add-custom-report-button {
      margin-top: 0.5rem;
  }
}
