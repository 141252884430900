@import "../../variables.scss";

div.dashboard-holder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;

  div.dashboard-loader {
    align-items: center;
    display: flex;
    flex-grow: 3;
    justify-content: center;
  }

  iframe {
    border: none;
    display: block;
    flex-grow: 1;
    width: 100%;
  }

  div.dashboard-error {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    & > div {
      max-width: 16rem;
      text-align: center;
    }

    div.error-image {
      height: 96px;
    }

    div.primary-text {
      margin-top: 0.2rem;
    }

    div.secondary-text {
      color: $orca-light-30;
      margin-top: 1rem;
    }
  }
}
