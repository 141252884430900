@import "../../variables.scss";


[slot="title"] {
    font-size: 1.2rem;
    color: $orca-light-30;
    display: flex;
    flex-direction: row;
  
    .title-span {
    margin: 0 1.5rem;
    }
}
  
[slot="body"] {
    direction: flex;
    flex-direction: column;
}
  
[slot="footer"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  
    .default-button-save {
      margin-left: .375rem;
    }
}

div.create-report-dialog-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

div.create-report-top {
  border-bottom: 1px solid $orca-light-50;
  padding-bottom: 0.5rem;
  margin-bottom: 3.125rem;
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: $orca-primary;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .subtitle {
    font-size: 0.8rem;
    color: $orca-light-50;
    margin-bottom: 0.2rem;
  }
}
  
div.create-report-name{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 7rem;

  .name-input {
    min-width: 18rem;
  }
}

div.link-report {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .report-id {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 21.25rem;

    .report-id-title {
      font-size: 0.85rem;
      color: $orca-light-15;
      min-width: 12.5rem;
    }

    .report-id-input-field {
        max-width: 7.5rem;
    }
  }
}
  
div.create-report-name .description, .link-report .description {
  min-width: 30rem;
}

div.create-report-name .title, .link-report .title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  color: $orca-primary;
}

div.create-report-name .subtitle, .link-report .subtitle {
  font-size: 0.8rem;
  color: $orca-light-50;
  margin-bottom: 1rem;
}
