@import "../../variables.scss";

div.clone-report-modal {
  width: 37.5rem;
  height: 18rem;
  background: white;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.create-report-dialog-loading {
    width: 18rem;
    height: 18rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  div.clone-report {
    margin: 3rem 10rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: $orca-primary;
    display: flex;
    flex-direction: column;
    align-items: center;

    .clone-report-input {
      margin-top: 2rem;
      width: 16rem;
    }
  
    div.clone-report-footer {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    
      .default-button-clone {
        margin-left: .375rem;
      }
    }
  }
}