@import "../../variables.scss";

.app-title-bar {
  z-index: 2;
}

.categories-dropdown {
  min-width: 9rem;
}

div.viewport-rest.computer {
  .heading-button-wrapper {
    min-width: 11.7rem;
  }

  .app-title-bar-icons {
    padding-left: 1.125rem;
    border-left: 1px solid $white-light-70;
  }

  .categories-dropdown {
    max-width: 12.5rem;
  }

  .categories-dropdown-container {
    border-right: 1px solid $white-light-70;
    padding-right: 0.75rem;
  }
}

div.viewport-rest.tablet {
  .categories-dropdown {
    width: 12rem;
  }

  div.app-title-bar-icons {
    padding-left: 1rem;
  }
}

div.viewport-rest.mobile {
  div.app-title-bar-icons {
    padding-left: 1rem;
  }
}

div.app-title-bar-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  min-height: 2.875rem;
}

.entity-loading-mask {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex: 1;

  h1 {
    color: $orca-primary;
    font-family: $font-montserrat;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.2px;
  }
}
